import { useState } from 'react';
import { useCookies } from "react-cookie";

export default function useToken() {
    const [cookies, setCookie, removeCookie] = useCookies(["raffle_user"]);
    
    var CryptoJS = require("crypto-js");

    const getToken = () => {       
        if (cookies.cloudstats_user) {
            
            var key_word = CryptoJS.enc.Utf8.parse("cloudstats@2024");
            var key = CryptoJS.enc.Base64.stringify(key_word);             
            
            var cookie_data = cookies.cloudstats_user;

            var bytes = CryptoJS.AES.decrypt(cookie_data, key,{iv:key, padding:CryptoJS.pad.ZeroPadding});
            var userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return userInfo
        }
        else { 
            return null;
        }
         
        /*
        if(sessionStorage.getItem('user'))
        {
            var bytes = CryptoJS.AES.decrypt(sessionStorage.getItem('user'), 'Raffles@2021');
            var userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));             
            return userInfo
        }
        else { 
            return null;
        }*/
    };

    const [userInfo, setToken] = useState(getToken());

    const saveToken = userInfo => {
        
        var key_word = CryptoJS.enc.Utf8.parse("cloudstats@2024");
        var key = CryptoJS.enc.Base64.stringify(key_word);
        
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userInfo), key,{iv:key, padding:CryptoJS.pad.ZeroPadding}).toString();

        setCookie("cloudstats_user", ciphertext, { path: "/", SameSite:"Strict" });    
        //sessionStorage.setItem('user', ciphertext);
        setToken(userInfo);
    };

    
    const removeToken = () => {        
        setCookie("cloudstats_user", "", { path: "/" });
        removeCookie('cloudstats_user');      
    };

    return {
        setToken: saveToken,
        userInfo        
    }
}
