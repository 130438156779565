import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';

export const AmazonCloud = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [cloudUsage, setcloudUsage] = useState(null);
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState({});
    const [polarData, setPolarData] = useState();
    const [polarOptions, setPolarOptions] = useState({});
    const [barOptions, setBarOptions] = useState({});
    const [currentMonth, setCurrentMonth] = useState();
    let current_month = "";
       
    useEffect(() => {
        fetch('/cloud/get_amazon_last_month_usage.php')
        .then(result => result.json())
        .then(rowData => setCloudUsageManual(rowData))
        {/*
        (async () => {            
            const lastMonthUsage = await fetch('/cloud/get_amazon_last_month_usage.php');
            const lastMonthData = await lastMonthUsage.json();
            setCloudUsageManual(lastMonthData);
                        
            let formData = new FormData();
            console.log("Month :"+current_month);
            formData.append('currMonth', current_month);
            const serviceMonthResponse = await fetch('/cloud/get_amazon_service_per_month_usage.php',{
                 method: 'POST',
                 body: formData,            
            });
            const serviceMonthData = await serviceMonthResponse.json();
            setPolarData(serviceMonthData);
        })();
        */}
    }, []);
    
       
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
      
        const polarOptions = {
            type: 'polarArea',
            data: polarData,
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Polar Area Chart'
                    }
                }
            },
        };
        const barOptions = {
          type: 'doughnut',
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          data: polarData,          
        };
        
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                title: {
                    display: true,
                    text: 'Productwise Monthly Expenses Chart',
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setPolarOptions(polarOptions);
        setBarOptions(barOptions);
        setChartOptions(options);
        fetch('/cloud/get_amazon_service_month_usage.php')
        .then(result => result.json())
        .then(rowData => setChartData(rowData));    
        
        
    }, []);
    
    const setTabChange = (value) => {
       console.log("Tab is changing to ",value);
       setTabIndex(value);
    }
    
    const loadServiceMonthDetails = async () => {        
        let formData = new FormData();
        
        formData.append('currMonth', current_month);
        const serviceMonthResponse = await fetch('/cloud/get_amazon_service_per_month_usage.php',{
             method: 'POST',
             body: formData,            
        });
        const serviceMonthData = await serviceMonthResponse.json();        
        setPolarData(serviceMonthData);       
    }
    
       
    const setCloudUsageManual = (value) => {
        setcloudUsage(value);   
        
        let CurrMonth = document.getElementById("CurrMonth");
        CurrMonth.textContent = value[0].start_date;
        setCurrentMonth(value[0].start_date);
        current_month = value[0].start_date;

        loadServiceMonthDetails();       
        
        let CurrMonthUsageId = document.getElementById("CurrMonthUsage");
        let CurrMonthUsage = parseFloat(value[0].total);
        CurrMonthUsageId.textContent = formatCurrency(CurrMonthUsage,"USD");
                
        let PervMonth = document.getElementById("PervMonth");
        let PervMonthUsageId = document.getElementById("PervMonthUsage");
        let PervMonthUsage = 0 ;
        
        if (value.length > 1) {
            PervMonthUsage = parseFloat(value[1].total);
            PervMonth.textContent = value[1].start_date;
        }
        
        PervMonthUsageId.textContent = formatCurrency(PervMonthUsage,"USD");
        
        let DiffUsageId = document.getElementById("DiffUsage");
        DiffUsageId.textContent = formatCurrency(CurrMonthUsage - PervMonthUsage,"USD");
    };
    
    
       
    
    const formatCurrency = (value,curr_type) => {              
        return value.toLocaleString('en-US', { minimumFractionDigits: 4, style:'currency', currency:curr_type });
    };

    const numberTemplate = (data, props) => {
        return (
            <>                
                <div style={{ textAlign: 'right' }}>{formatCurrency(parseFloat(data[props.field]),data.currency)}</div>
            </>
        );
    };

    return (        
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span id ="CurrMonth" className="title">Current Month</span>
                    <span className="detail">Total Value</span>
                    <span id ="CurrMonthUsage" className="count visitors">$0</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span id ="PervMonth" className="title">Previous Month</span>
                    <span className="detail">Total Value</span>
                    <span id ="PervMonthUsage" className="count purchases">$0</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Difference</span>
                    <span className="detail">Total Difference</span>
                    <span id ="DiffUsage"className="count revenue">$0</span>
                </div>
            </div>
            
            <div className="card">            
                <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
                    <TabPanel header="Cumulative Monthly Service Details (Polar)">
                        <p className="m-0">
                            <div className="p-col-12">
                                <div className="product-polarchart-container">
                                    <h5 className="p-text-center">Cumulative Service Details for month : {currentMonth} (Polar Chart)</h5>
                                    <Chart type="polarArea" data={polarData} options={polarOptions} />                    
                                </div>
                            </div>
                        </p>
                    </TabPanel>
                    <TabPanel header="Cumulative Monthly Service Details (Donut)">
                        <p className="m-0">
                            <div className="p-col-12">
                                <div className="product-polarchart-container">
                                    <h5 className="p-text-center">Cumulative Service Details for month : {currentMonth} (Donut Chart)</h5>
                                    <Chart type="doughnut" data={polarData} options={barOptions} />
                                </div>
                            </div>
                        </p>
                    </TabPanel>
                    <TabPanel header="Daily Service Details">
                        <p className="m-0">
                            <div className="p-col-12">
                                <div className="product-polarchart-container">
                                    <h5 className="p-text-center">Detailed Daywise Service Details for month : {currentMonth}</h5>
                                    <Chart type="bar" data={chartData} options={chartOptions} />
                                </div>
                            </div>
                        </p>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    );
}

