import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860'
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e'
        }
    ]
};

export const Home = () => {   
    const [cloudUsage, setcloudUsage] = useState(null);
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});   
    
    useEffect(() => {
        fetch('/cloud/get_cloud_last_month_usage.php')
        .then(result => result.json())
        .then(rowData => setCloudUsageManual(rowData))
    }, []);
    
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        /*
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    type: 'bar',
                    label: 'Dataset 1',
                    backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [50, 25, 12, 48, 90, 76, 42]
                },
                {
                    type: 'bar',
                    label: 'Dataset 2',
                    backgroundColor: documentStyle.getPropertyValue('--green-500'),
                    data: [21, 84, 24, 75, 37, 65, 34]
                },
                {
                    type: 'bar',
                    label: 'Dataset 3',
                    backgroundColor: documentStyle.getPropertyValue('--yellow-500'),
                    data: [41, 52, 24, 74, 23, 21, 32]
                }
            ]
        };
        */
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };
        
        fetch('/cloud/get_cloud_service_month_usage.php')
        .then(result => result.json())
        .then(rowData => setChartData(rowData));

        //setChartData(data);
        setChartOptions(options);
    }, []);
    
    
    const setCloudUsageManual = (value) => {
        setcloudUsage(value);   
        
        let CurrMonth = document.getElementById("CurrMonth");
        CurrMonth.textContent = value[0].start_date;
        let CurrMonthUsageId = document.getElementById("CurrMonthUsage");
        let CurrMonthUsage = parseFloat(value[0].total);
        CurrMonthUsageId.textContent = formatCurrency(CurrMonthUsage,"USD");
        
        let PervMonth = document.getElementById("PervMonth");
        PervMonth.textContent = value[1].start_date;
        let PervMonthUsageId = document.getElementById("PervMonthUsage");
        let PervMonthUsage = parseFloat(value[1].total);
        PervMonthUsageId.textContent = formatCurrency(PervMonthUsage,"USD");
        
        let DiffUsageId = document.getElementById("DiffUsage");
        DiffUsageId.textContent = formatCurrency(CurrMonthUsage - PervMonthUsage,"USD");
        
    };
       
    
    const formatCurrency = (value,curr_type) => {              
        return value.toLocaleString('en-US', { minimumFractionDigits: 4, style:'currency', currency:curr_type });
    };

    const numberTemplate = (data, props) => {
        return (
            <>                
                <div style={{ textAlign: 'right' }}>{formatCurrency(parseFloat(data[props.field]),data.currency)}</div>
            </>
        );
    };

    return (        
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span id ="CurrMonth" className="title">Current Month</span>
                    <span className="detail">Total Value</span>
                    <span id ="CurrMonthUsage" className="count visitors">$0</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span id ="PervMonth" className="title">Previous Month</span>
                    <span className="detail">Total Value</span>
                    <span id ="PervMonthUsage" className="count purchases">$0</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Difference</span>
                    <span className="detail">Total Difference</span>
                    <span id ="DiffUsage"className="count revenue">$0</span>
                </div>
            </div>           
            
            <div className="p-col-12">
                <div className="card">
                    <Chart type="bar" data={chartData} options={chartOptions} />
                </div>
            </div>
        </div>
    );
}
