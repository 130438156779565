import React from 'react';

export const AppFooter = () => {

    return (
        <div className="layout-footer">
            <img src="/cloudstats_blue.png" alt="Logo" width="140" height="40"/>            
            <span className="footer-text" style={{ 'marginLeft': '5px'}}>Copyright &copy; 2024</span>
        </div>
    );
}
