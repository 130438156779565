import React, { useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import OutsideClickHandler from 'react-outside-click-handler';
import {Link} from 'react-router-dom'
import { useNavigate} from 'react-router-dom'
import  useToken from './components/useToken';

export const AppTopbar = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { userInfo, getToken } = useToken();
    const navigate = useNavigate();

    function checkLogin() {        
        if(!props.userState) {
        //if(!userInfo) {
            return (
                <>
                    <Link to="/Login">
                        <button type="button" className="p-link" >
                            <span className="layout-topbar-item-text">User</span>
                            <span className="layout-topbar-icon pi pi-user" />
                        </button>
                    </Link>
                    {/*
                    <Link to="/Register">
                        <button type="button" className="p-link" >
                            <span className="layout-topbar-item-text">Register</span>
                            <span className="layout-topbar-icon pi pi-user-plus" />
                        </button>
                    </Link>
                    */}
                </>
            )
        }
        else {
            return (
                <>
                {/* <OutsideClickHandler onOutsideClick={() => { if (expanded) { setExpanded(false);} }} >                   */}
                    <button type="button" className="p-link" onClick={onClick}>
                        <span className="layout-topbar-item-text">User</span>
                        <span className="layout-topbar-icon pi pi-user" />
                    </button>                
                    <div class='layout-profile'> 
                        <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>                    
                            <ul className={classNames({ 'layout-profile-expanded': expanded })}>                                
                                <li><button type="button" className="p-link" onClick={onChangePassword}><i className="pi pi-fw pi-user" /><span>Change Password</span></button></li>
                                <li><button type="button" className="p-link" onClick={onLogout}><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li>                            
                            </ul>                    
                        </CSSTransition>
                    </div>
                {/* </OutsideClickHandler> */}
                </>
            )
        } 
    }

    const onClick = (event) => {
        setExpanded(prevState => !prevState);
        event.preventDefault();
    }

    const onAccountDetails = (event) => {
        setExpanded(false);
        navigate('/AccountDetails');
    }

    const onChangePassword = (event) => {
        setExpanded(false);
        navigate('/ChangePassword');
    }

    const onLogout = (event) => {
        setExpanded(false);
        navigate('/Logout');
    }

    return (
        <div className="layout-topbar clearfix">
            <span className="layout-topbar-logo">            
            <button type="button" className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                <span className="pi pi-bars" />
            </button>
            &nbsp;
            <Link to="/"><img alt="Logo" src='/cloudstats_white.png' /></Link>
            </span>
            <OutsideClickHandler onOutsideClick={() => { if (expanded) { setExpanded(false);} }} >
                <div className="layout-topbar-icons">                
                    <Link to="/">
                    <button type="button" className="p-link" >
                        <span className="layout-topbar-item-text">Home</span>
                        <span className="layout-topbar-icon pi pi-home" />
                    </button></Link>                
                    {checkLogin()}         
                </div>
            </OutsideClickHandler>
        </div>
    );  
}
